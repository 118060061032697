// src/admin/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import AdminPanel from './AdminPanel';
import './AdminPanel.css'; // 引入CSS文件

const rootElement = document.getElementById('admin-root');
console.log('Root element:', rootElement);

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<AdminPanel />);
} else {
  console.error('Admin root element not found');
}
