// src/admin/AdminPanel.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminPanel.css'; // 引入CSS文件

function AdminPanel() {
    const [projectId, setProjectId] = useState('');
    const [title, setTitle] = useState('');
    const [images, setImages] = useState(['']);
    const [password, setPassword] = useState('');
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        axios.get('http://localhost:3001/api/projects')
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch projects', error);
            });
    }, []);

    const updateProject = () => {
        axios.post(`http://localhost:3001/api/projects/${projectId}`, { title, images, password })
            .then(response => {
                alert('项目更新成功');
                setProjectId('');
                setTitle('');
                setImages(['']);
                setPassword('');
            })
            .catch(error => {
                alert('项目更新失败。请确保您输入了正确的密码。');
            });
    };

    const addImageField = () => {
        setImages([...images, '']);
    };

    const handleImageChange = (index, value) => {
        const newImages = [...images];
        newImages[index] = value;
        setImages(newImages);
    };

    const handleFileUpload = (index, event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            axios.post('http://localhost:3001/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                const newImages = [...images];
                newImages[index] = response.data.filePath; // 从服务器返回的文件路径
                setImages(newImages);
            })
            .catch(error => {
                console.error('Failed to upload file', error);
            });
        }
    };

    const handleProjectChange = (e) => {
        const selectedProjectId = e.target.value;
        const selectedProject = projects.find(project => project.id === parseInt(selectedProjectId));
        if (selectedProject) {
            setProjectId(selectedProjectId);
            setTitle(selectedProject.title);
            setImages(selectedProject.images);
        }
    };

    return (
        <div className="admin-panel">
            <h2>管理面板</h2>
            <select value={projectId} onChange={handleProjectChange}>
                <option value="" disabled>选择项目 ID</option>
                {projects.map(project => (
                    <option key={project.id} value={project.id}>{project.id}</option>
                ))}
            </select>
            <input 
                type="text" 
                placeholder="标题" 
                value={title} 
                onChange={(e) => setTitle(e.target.value)} 
            />
            {images.map((image, index) => (
                <div key={index} className="image-field">
                    <input 
                        type="text" 
                        placeholder={`图片 ${index + 1} 路径`} 
                        value={image} 
                        onChange={(e) => handleImageChange(index, e.target.value)} 
                    />
                    <input 
                        type="file" 
                        accept="image/*" 
                        onChange={(e) => handleFileUpload(index, e)} 
                    />
                </div>
            ))}
            <button onClick={addImageField}>添加图片</button>
            <input 
                type="password" 
                placeholder="管理员密码" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
            />
            <button onClick={updateProject}>更新项目</button>
        </div>
    );
}

export default AdminPanel;
